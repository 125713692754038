exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-[type]-[id]-jsx": () => import("./../../../src/pages/preview/[type]/[id].jsx" /* webpackChunkName: "component---src-pages-preview-[type]-[id]-jsx" */),
  "component---src-pages-recherche-jsx": () => import("./../../../src/pages/recherche.jsx" /* webpackChunkName: "component---src-pages-recherche-jsx" */),
  "component---src-templates-article-template-jsx": () => import("./../../../src/templates/articleTemplate.jsx" /* webpackChunkName: "component---src-templates-article-template-jsx" */),
  "component---src-templates-event-template-jsx": () => import("./../../../src/templates/eventTemplate.jsx" /* webpackChunkName: "component---src-templates-event-template-jsx" */),
  "component---src-templates-formation-template-jsx": () => import("./../../../src/templates/formationTemplate.jsx" /* webpackChunkName: "component---src-templates-formation-template-jsx" */),
  "component---src-templates-job-template-jsx": () => import("./../../../src/templates/jobTemplate.jsx" /* webpackChunkName: "component---src-templates-job-template-jsx" */),
  "component---src-templates-magazine-pdf-template-jsx": () => import("./../../../src/templates/magazinePdfTemplate.jsx" /* webpackChunkName: "component---src-templates-magazine-pdf-template-jsx" */),
  "component---src-templates-magazine-template-jsx": () => import("./../../../src/templates/magazineTemplate.jsx" /* webpackChunkName: "component---src-templates-magazine-template-jsx" */),
  "component---src-templates-news-template-jsx": () => import("./../../../src/templates/newsTemplate.jsx" /* webpackChunkName: "component---src-templates-news-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-pharmacy-formation-template-jsx": () => import("./../../../src/templates/pharmacyFormationTemplate.jsx" /* webpackChunkName: "component---src-templates-pharmacy-formation-template-jsx" */),
  "component---src-templates-pharmacy-template-jsx": () => import("./../../../src/templates/pharmacyTemplate.jsx" /* webpackChunkName: "component---src-templates-pharmacy-template-jsx" */),
  "component---src-templates-prestation-template-jsx": () => import("./../../../src/templates/prestationTemplate.jsx" /* webpackChunkName: "component---src-templates-prestation-template-jsx" */),
  "component---src-templates-taxonomy-template-jsx": () => import("./../../../src/templates/taxonomyTemplate.jsx" /* webpackChunkName: "component---src-templates-taxonomy-template-jsx" */)
}

